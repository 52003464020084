export const UUID_PATTERN =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const ID_PATTERN = '\\d+';

export const ALLOWED_URL_PATTERNS = [
  // main
  '/defaults',
  '/version',
  '/orgs/:uuid/',
  '/orgs/:uuid/query_options/',
  '/orgs/:uuid/summary?meta=.*',
  '/auth/identity_providers',

  // charts
  '/prometheus/api/v1/query_range&query=.*&start=.*&end=.*&step=.*',

  // activity
  '/activity?page=.*&page_size=.*&(min_date=.*)&(max_date=.*)&(resource_id=.*)&(resource_type=.*)&(action=.*)&(user_id=.*)',


  // auth
  '/login',
  '/change_password',
  '/reset_password',
  '/password_reset_confirm/',
  '/verifyaccount/.*/.*/',

  // roles
  '/roles',
  '/roles/.*',
  '/roles/:uuid',
  '/roles/permissions',

  // users
  '/users',
  '/users/:uuid',
  '/users/:uuid/remove_roles/',
  'users/:uuid/add_roles/',
  '/users?exclude_pending=.*',

  // invites
  '/invites',
  '/invites/.*',
  '/invites/:uuid',
  '/invites/.*/resend_invite',
  '/invites/bulk_invite/',
  '/invites?pending_only=.*',

  // orgs
  '/orgs/:uuid',
  '/orgs/:uuid/activity?page=.*&page_size=.*&(min_date=.*)&(max_date=.*)',

  // projects
  '/orgs/:uuid/projects',
  '/orgs/:uuid/projects/',
  '/orgs/:uuid/projects/:uuid',
  '/orgs/:uuid/projects/:uuid/',
  '/orgs/:uuid/projects/:uuid/activity?page=.*&page_size=.*&(min_date=.*)&(max_date=.*)',

  // tables
  '/orgs/:uuid/projects/:uuid/tables',
  '/orgs/:uuid/projects/:uuid/tables/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/activity?page=.*&page_size=.*&(min_date=.*)&(max_date=.*)',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/views',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/views/:uuid',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/views/:uuid/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/truncate/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/query_options_hierarchy',

  // dictionary
  '/dictionary_layouts/',
  '/dictionary_input_formats/',
  '/orgs/:uuid/projects/:uuid/dictionaries',
  '/orgs/:uuid/projects/:uuid/dictionaries/',
  '/orgs/:uuid/projects/:uuid/dictionaries/files',
  '/orgs/:uuid/projects/:uuid/dictionaries/files/',
  '/orgs/:uuid/projects/:uuid/dictionaries/files/.*/',
  '/orgs/:uuid/projects/:uuid/dictionaries/:uuid',
  '/orgs/:uuid/projects/:uuid/dictionaries/:uuid/',
  '/orgs/:uuid/projects/:uuid/generateschema?type=dictionary',

  // files
  '/orgs/:uuid/projects/:uuid/dictionaries/files/',
  '/orgs/:uuid/projects/:uuid/dictionaries/files/:uuid',

  // functions
  '/orgs/:uuid/projects/:uuid/functions',
  '/orgs/:uuid/projects/:uuid/functions/',
  '/orgs/:uuid/projects/:uuid/functions/:uuid',
  '/orgs/:uuid/projects/:uuid/functions/:uuid/',

  // query
  '/query/',
  '/query/?service=.*',
  '/query/?poolNames=.*&appColumnsSql=.*',
  '/query/?component=.*&poolNames=.*&appColumnsSql=.*',
  '/orgs/:uuid/query_options',

  // jobs
  '/job_statuses/',
  '/orgs/:uuid/purgejobs/',

  '/orgs/:uuid/jobs/alter/',
  '/orgs/:uuid/jobs/alter/:uuid',
  '/orgs/:uuid/jobs/alter/:uuid/',
  'orgs/:uuid/jobs/alter/:uuid/cancel',
  'orgs/:uuid/jobs/alter/:uuid/commit',
  'orgs/:uuid/jobs/alter/:uuid/retry',
  '/orgs/:uuid/jobs/alter?page=.*&page_size=.*',
  '/orgs/:uuid/jobs/alter?page=.*&page_size=.*&(table_id=.*)&(date_min=.*)&(date_max=.*)&(status=.*)',

  '/orgs/:uuid/jobs/batch/',
  '/orgs/:uuid/jobs/batch/:uuid',
  '/orgs/:uuid/jobs/batch/:uuid/',
  'orgs/:uuid/jobs/batch/:uuid/cancel',
  'orgs/:uuid/jobs/batch/:uuid/retry',
  '/orgs/:uuid/jobs/batch?page=.*&page_size=.*',
  '/orgs/:uuid/jobs/batch?page=.*&page_size=.*&(table_id=.*)&(date_min=.*)&(date_max=.*)&(status=.*)&(name=.*)',

  // transform
  '/validator',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/transforms/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/transforms/:uuid',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/transforms/:uuid/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/generateschema',

  // transform templates
  '/transform_templates',

  // sources
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/kafka/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/kafka/:uuid',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/kafka/:uuid/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/kinesis/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/kinesis/:uuid',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/kinesis/:uuid/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/siem/',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/siem/:uuid',
  '/orgs/:uuid/projects/:uuid/tables/:uuid/sources/siem/:uuid/',

  // scale
  '/pools',
  '/pools/:uuid',
  '/pools?service=.*',
  '/instancetypes',

  // auth logsInvalid URL or URL does not match allowed patterns: /auth_logs?from_date=2025-02-27&to_date=2025-04-02&page_size=undefined
  '/auth_logs?from_date=.*&to_date=.*&page=.*&page_size=.*',
  '/auth_logs?from_date=.*&to_date=.*&page=.*&page_size=.*&user_id=.*',
  '/auth_logs?from_date=.*&to_date=.*&page=.*&page_size=.*&(event_types=.*)+',
  '/auth_logs?from_date=.*&to_date=.*&page=.*&page_size=.*&(event_types=.*)+&user_id=.*',

  // buckets
  '/orgs/:uuid/storages',
  '/orgs/:uuid/storages/',
  '/orgs/:uuid/storages/:uuid',
  '/orgs/:uuid/storages/:uuid/',

  // credentials
  '/orgs/:uuid/credentials',
  '/orgs/:uuid/credentials/types',
  '/orgs/:uuid/credentials/:uuid',
  '/orgs/:uuid/credentials/:uuid/',

  // stats
  '/orgs/:uuid/projects/:uuid/tables/:uuid/stats',
];

export const ALLOWED_URL_PATTERNS_WITH_REGULAR_ID = ['/transform_templates/:id'];
